export default ({ label, isChecked = false, onChange = () => false }) => {

	return <div className='switch'>
		<label className="switch">
			<input type="checkbox" checked={!!isChecked} onChange={() => onChange(!isChecked)} />
			<span className ="slider round"></span>
		</label>
		{label && <div className='label'>{label}</div>}
	</div>

}