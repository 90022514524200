export default ({ 
	children, 
	closeAction = () => false,
	allowBackdropClose = false
}) => (
	<div className='backdrop active' onClick={() => {
		if (allowBackdropClose) closeAction()
	}}>
		<div className='modal' onClick={e => e.stopPropagation()}>
			{children}
			<div id='modal-close' onClick={closeAction}><i className="las la-times"></i></div>
		</div>
	</div>
)