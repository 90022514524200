export default ({
	label,
	isSub = false,
	isLight=false,
	onClick = () => false,
	isDisabled = false,
	icon,
	isFluid=false
}) => {

	return <div 
		className={`button ${icon ? '' : 'no-icon'} ${isSub ? 'sub' : ''} ${isLight ? 'light' : ''} ${isDisabled ? 'disabled' : ''} ${isFluid ? 'fluid' : ''}`}
		onClick={e => isDisabled ? false : onClick(e)}
	>
		{icon && <i className={`las ${icon}`}></i>}
		{label && <div>{label}</div>}
	</div>

}