export default ({ 
	tabs = [], 
	currentTab, 
	onChange = () => false, 
	onLogout = () => false 
}) => {

	return (
		<div id="header">
			<div className="header-container">
				<div className="menu">
					{tabs && tabs.length && tabs.map((tab, i) => (
						<div 
							className={`item ${currentTab === i ? 'active' : ''}`}
							key={i}
							onClick={() => onChange(i)}
						>{tab}</div>
					))}
				</div>
				<div className="menu">
					<div className="item" onClick={() => onLogout()}>Logout</div>
				</div>
			</div>
		</div>
	)

}