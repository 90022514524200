import { useState, useCallback } from 'react'

export default ({ 
	label, 
	description, 
	items = [], 
	selectedIndex, 
	defaultText = '', 
	isError = false,
	onSelect = () => false, 
	onOpen = () => false,	
}) => {

	const [isActive, setIsActive] = useState(false)

	const selectHandler = useCallback((e, i) => {
		e.stopPropagation()
		onSelect(i)
		setIsActive(false)
	}, [onSelect])

	const openHandler = useCallback(e => {
		e.stopPropagation()
		if (!isActive) onOpen()
		setIsActive(!isActive)
	}, [onOpen, isActive])

	return <div className={`input-container ${isError ? 'error' : ''}`}>

		{label && <div className="label">{label}</div>}

		<div 
			className={`dropdown ${isActive ? 'active' : ''}`} 
			onClick={openHandler}
		>

			<div className='selected'>
				<div>{selectedIndex !== false && selectedIndex !== undefined && selectedIndex !== null ?
					items[selectedIndex] : defaultText || ''}</div>
				<div><i className="las la-angle-down"></i></div>
			</div>

			{items && items.length ? <div className='list'>
				{items.map((item, i) => (
					<div 
						className={`item ${selectedIndex === i ? 'selected' : ''}`} 
						key={i} 
						onClick={e => selectHandler(e, i)}
					>
						<div>{item}</div>
					</div>
				))}
			</div> : null}

		</div>
		{description && <div className="description">{description}</div>}
	</div>

}