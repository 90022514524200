import { useMemo, useCallback, useState, useEffect } from 'react'
import DataTable from "react-data-table-component"

import Button from '../components/Button'
import TextInput from '../components/TextInput'
import Dropdown from '../components/Dropdown'
import Switch from '../components/Switch'
import CopyBox from "../components/CopyBox"

import axios from 'axios'
import { APIURL, useNestedState, useEntityREST } from '../utils'

const tokenize = (str) => {
	var results = [], re = /{([^}]+)}/g, text;

	while (text = re.exec(str)) {
		results.push(text[1]);
	}

	return results;
}

export default ({ linkId, closeAction, activitiesArr, activityCodeToName }) => {

	// Load/Save REST ---

	const [validationResult, setValidationResult] = useState({})

	const saveAction = useCallback((result) => {
		if (result.status === 200 || result.status === 201) closeAction()
		else {
			if (result.response?.data?.isValid === false) {
				let obj = {}
				obj[result.response.data.key] = result.response.data.message
				setValidationResult(obj)
			}
			else setValidationResult({})
		}
	}, [closeAction])

	const [linkDef, setLinkDef, setField] = useNestedState({
		linkId: "",
		title: "",
		exitUrl: "",
		activityId: 208,
		activityParams: {},
		enabled: true
	})
	const saveData = useEntityREST({
		type: 'relink', 
		state: linkDef, 
		setState: setLinkDef, 
		id: linkId, 
		callbackFn: saveAction,
		customSaveFormatter: (data) => ({ linkDefinition: data })
	})

	// Map Activities ---

	const [activityCodeToIndex, setActivityCodeToIndex] = useState({})

	useEffect(() => {
		let codeToIndex = {}
		activitiesArr.forEach((activity, i) => codeToIndex[activity.ActivityEvent] = i)
		setActivityCodeToIndex(codeToIndex)
	}, [activitiesArr])

	// Activity Schema Fields

	const [activityFields, setActivityFields] = useState([])

	const getDisplayNameFromField = useCallback((key) => {
		const activityIndex = activityCodeToIndex[linkDef.activityId]
		if (!activityIndex) return key

		const activity = activitiesArr[activityIndex]
		if (!activity.Fields) return key

		const foundField = activity.Fields.find(field => field.SchemaName === key)
		if (!foundField) return key
		return foundField.DisplayName || key
	}, [activitiesArr, linkDef])

	const syncLinkDefParamsToActivity = useCallback((activityIndex) => {
		const activity = activitiesArr[activityIndex]
		if (!activity) return

		let params = {}		
		if (activity.Fields) activity.Fields.forEach(field => {
			if (field.ShowInForm === true) params[field.SchemaName] = ''
		})	

		setLinkDef({
			...linkDef,
			activityId: activity.ActivityEvent,
			activityParams: params
		})	
	}, [linkDef, activitiesArr, activityCodeToIndex])

	const getFieldsFromLinkDefParams = useCallback(() => {
		let fields = []
		Object.keys(linkDef.activityParams).forEach(key => fields.push({key, value: linkDef.activityParams[key]}))
		setActivityFields(fields)
	}, [linkDef])

	useEffect(() => getFieldsFromLinkDefParams(), [linkDef])

	const onChangeLinkDefParams = useCallback((key, value) => {
		let params = { 
			...linkDef.activityParams 
		}
		params[key] = value

		setLinkDef({
			...linkDef,
			activityParams: params
		})	
	}, [linkDef])

	const columns = useMemo(() => {
		return [
			{
				name: 'Field',
				selector: row => getDisplayNameFromField(row.key),
			},
			{
				name: 'Value',
				selector: row => <TextInput 
					placeholder='Value' 
					value={row.value} 
					isSub={true}
					onChange={value => onChangeLinkDefParams(row.key, value)}
				/>,
			},
		]
	}, [onChangeLinkDefParams, getDisplayNameFromField])

	// Relay URL calculator ---

	const relayUrl = useMemo(() => {
		let url = `${APIURL()}/relink/${linkDef.linkId}?emailId=@{Lead:EmailAddress}`
		
		let tokensMap = {}
		const addTokenToQueryParam = (token) => {
			if (!tokensMap[token]) {
				tokensMap[token] = true
				url = `${url}&${token}=@{Lead:XXXX}`
			}
		}

		tokenize(linkDef.exitUrl).forEach(addTokenToQueryParam)
		Object.values(linkDef.activityParams).forEach(v => tokenize(v).forEach(addTokenToQueryParam))
		
		return url
	}, [linkDef])

	// Render

	return <div className="form-container">
		<div className="form-header"><h2>New Redirect</h2></div>
		<div className="form-content">
			<div className="row">
				<TextInput
					label={'Name'}
					description={validationResult["title"] || 'Give a name to help identify this redirect.'}
					isError={!!validationResult["title"]}
					placeholder={'Example Link 1'}
					value={linkDef.title}
					onChange={v => setField('title', v)}
				/>
				<Dropdown
					label={'Activity'}
					description={validationResult["activityId"] || 'Select activity to be sent to the CRM.'}
					isError={!!validationResult["activityId"]}
					items={activitiesArr.map(activity => activity.DisplayName)}
					selectedIndex={activityCodeToIndex[linkDef.activityId] || 0}
					onSelect={i => syncLinkDefParamsToActivity(i)}
				/>
			</div>
			<div className="row">
				<TextInput
					label={'Destination URL'}
					description={validationResult["exitUrl"] || 'Users will be forwarded to this URL. Use curly braces to insert variables.'}
					isError={!!validationResult["exitUrl"]}
					placeholder={'https://www.google.com?q={{query}}'}
					value={linkDef.exitUrl}
					onChange={v => setField('exitUrl', v)}
				/>
				<TextInput
					label={'Path'}
					description={validationResult["linkId"] || 'Path segment for the Relay URL. Whitespace and special characters are not allowed.'}
					isError={!!validationResult["linkId"]}
					placeholder={'example1'}
					value={linkDef.linkId}
					onChange={v => setField('linkId', v)}
				/>
			</div>
			<div className="row">
				<div className="section">
					<div className="label">Fields</div>
					<div className="description" style={{ marginBottom: '5px' }}>
						Data sent to the CRM as part of the activity. Use curly braces to insert variables.<br />
					</div>
					<DataTable
						columns={columns}
						data={activityFields}
						theme="univ"
					/>
				</div>
			</div>
			<div className="row">
				<div className="section">
					<div className="label">Status</div>
					<Switch 
						label={linkDef.enabled ? "Enabled" : "Disabled"} 
						isChecked={linkDef.enabled} 
						onChange={v => setField('enabled', v)}
					/>
				</div>
			</div>
			<div className="row">
				<div className="section">
					<div className="label">Relay URL</div>
					<div className="description">
						URL to be used in emailers etc.
					</div>
					<CopyBox value={relayUrl} />
				</div>
			</div>
		</div>
		<div className="section">
			<div>
				<Button label="Done" onClick={saveData} />
			</div>
			<div>

			</div>
		</div>
	</div>

}