import React from 'react'
import ReactDOM from 'react-dom'
import { createTheme } from "react-data-table-component"

import './globals.scss'
import App from './App'

createTheme('univ', {
	background: {
		default: '#fafafa'
	}
})

ReactDOM.render(<App />, document.getElementById('root'))
