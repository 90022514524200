export default ({ value }) => {

	return <div className='copy-box'>
		<div className='icon' onClick={() => navigator.clipboard.writeText(value)} >
			<i className='las la-clipboard'></i>
			<div>Click to Copy</div>
		</div>
		<div className='value'>{value}</div>
	</div>

}