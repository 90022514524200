import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'

import { APIURL } from './utils'

import MainHeader from './components/MainHeader'
import Home from './views/Home'
import Redirects from './views/Redirects'

const views = [Home, Redirects]

const App = ({}) => {

	const [isReady, setIsReady] = useState(false)
	const [currentView, setCurrentView] = useState(0)

	const loadSession = useCallback(async () => {
		try {
			setIsReady(true)
			// const result = await axios.get(`${APIURL()}/status/me`, { withCredentials: true })
			// if (result.data) setIsReady(true)
			// else window.location.replace('https://univ.ai/login')
		}
		catch (err) {
			
		}
	}, [])
	useEffect(() => loadSession(), [loadSession])

	const View = views[currentView]

	if (!isReady) return null
	else return (<React.Fragment>
		<MainHeader 
			tabs={['Home', 'Redirects']} 
			currentTab={currentView} 
			onChange={setCurrentView}
		/>
		<div id="page">
			<div className='content'><View /></div>
		</div>
		<div id="footer">
			<div className="footer-container">
				<div>Copyright &copy; Univ.AI 2021</div>
				<div>Agency 1.0</div>
			</div>
		</div>
	</React.Fragment>)

}

export default App