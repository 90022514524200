import React, { useEffect, useState, useCallback, useMemo } from "react"

import _ from 'lodash'
import axios from 'axios'

export const APIURL = () => {
	const dev_url = `http://localhost:9000`
	const live_url = `${window.location.protocol}//dispatch.staging.univ.ai`

	if (window.location.hostname !== 'localhost') return live_url;
	else return dev_url;
}

export const useNestedState = initialValue => {
	const [state, setState] = useState(initialValue)

	const setField = useCallback((field, val) => {
		let newObj = _.cloneDeep(state)

		const updateNestedField = (obj, keyArr, level = 0) => {
			if (level === keyArr.length - 1) {
				obj[keyArr[level]] = val
			}
			else {
				if (typeof obj[keyArr[level]] === 'object') updateNestedField(obj[keyArr[level]], keyArr, level + 1)
				else debugger
			}
		}

		updateNestedField(newObj, field.split('.'))
		setState(newObj)

	}, [state])

	return [state, setState, setField]
}

const mapShallowKeys = (obj, shallowKeyMap) => {
	let newObj = {}
	Object.keys(obj).forEach(key => {
		const newKey = shallowKeyMap[key] || key
		newObj[newKey] = obj[key]
	})
	return newObj
}

export const useEntityREST = ({
	type, 
	state, 
	setState, 
	id = false, 
	callbackFn, 
	shallowKeyMap = {}, 
	useFormData = false, 
	fileFieldsMap = {}, 
	customSaveFormatter = (a) => a
}) => {

	useEffect(async () => {
		if (id) {
			const result = await axios.get(`${APIURL()}/admin/${type}/${id}`, { withCredentials: true })
			setState(mapShallowKeys(result.data, shallowKeyMap))
		}
	}, [id])

	const saveData = useCallback(async () => {

		let data = state, options = { withCredentials: true }

		if (useFormData) {
			data = new FormData()

			Object.keys(state).forEach(key => {
				data.append(
					key, 
					typeof state[key] === 'object' && !fileFieldsMap[key] ? JSON.stringify(state[key]) : state[key]
				)
			})

			options.headers = { 'content-type': 'multipart/form-data' }
		}

		let result
		try {
			result = await axios.post(`${APIURL()}/admin/${type}${id ? `/${id}` : ''}`, customSaveFormatter(data), options)
			if (callbackFn) callbackFn(result)
		}
		catch (err) {
			if (callbackFn) callbackFn(result || err)
		}

	}, [state, id])

	return saveData
}

// export const praxis = new Proxy({}, {
// 	get: async (_, HTTPMethod, __) => {
// 		return (...args) => {

// 			// Pre
// 			// ---

// 			// Fire API
// 			const result = await axios[HTTPMethod](...args)

// 			// Post
// 			// ---

// 		}
// 	}
// })