export default ({ 
	label, 
	description, 
	placeholder, 
	value, 
	isSub = false,
	onChange = () => false, 
	isError = false
}) => {

	return <div className={`input-container ${isError ? 'error' : ''}`}>
		{label && <div className="label">{label}</div>}
		<input 
			className={isSub ? 'sub' : ''} 
			type='text' 
			value={value} 
			placeholder={placeholder || ''} 
			onChange={e => onChange(e.target.value)}
		/>
		{description && <div className="description">{description}</div>}
	</div>

}