import React, { useState, useCallback, useEffect, useMemo } from "react"
import DataTable from "react-data-table-component"

import axios from 'axios'
import { APIURL, useNestedState, useEntityREST } from '../utils'

import Button from '../components/Button'
import TextInput from '../components/TextInput'
import Dropdown from '../components/Dropdown'
import Modal from '../components/Modal'

import ReLink from '../modals/ReLink'

const Redirects = ({}) => {

	// Fetch Unfiltered Raw Data ---

	const [redirectsArr, setRedirectsArr] = useState([])
	const [activitiesArr, setActivitiesArr] = useState([])
	const [activityCodeToName, setActivityCodeToName] = useState({})

	const refreshTable = useCallback(async () => {
		const results = await axios.get(
			`${APIURL()}/admin/relink`,
			{ withCredentials: true },
		)
		setRedirectsArr(results.data)
	}, [])

	const refreshActivities = useCallback(async() => {
		const results = await axios.get(
			`${APIURL()}/admin/ls/activities`,
			{ withCredentials: true },
		)

		let sortedActivities = [...results.data.activities].sort((a, b) => {
			if (a.DisplayName < b.DisplayName) return -1
			else if (a.DisplayName > b.DisplayName) return 1
			else return 0
		})

		setActivitiesArr(sortedActivities)

		let codeToName = {}
		sortedActivities.forEach(activity => codeToName[activity.ActivityEvent] = activity.DisplayName)
		setActivityCodeToName(codeToName)
	}, [])

	useEffect(() => {
		refreshTable()
		refreshActivities()
	}, [])

	const columns = useMemo(() => {
		return [
			{
				name: 'Name',
				selector: row => row.title,
			},
			{
				name: 'Activity',
				selector: row => activityCodeToName[row.activityId] || row.activityId,
			},
			{
				name: 'Relay',
				selector: row => row.linkId,
			},
			{
				name: 'Destination',
				selector: row => row.exitUrl,
			},
			{
				name: 'Status',
				selector: row => row.enabled ? 'Enabled' : 'Disabled',
			},
		]
	}, [activityCodeToName])

	// Implement Filters ---

	const [nameFilter, setNameFilter] = useState('')
	const [pathFilter, setPathFilter] = useState('')
	
	const [statusFilter, setStatusFilter] = useState(null)
	const [statusFilterIndex, setStatusFilterIndex] = useState(0)
	
	const [activityFilter, setActivityFilter] = useState(null)
	const [activityFilterIndex, setActivityFilterIndex] = useState(0)

	const [filteredRedirectsArr, setFilteredRedirectsArr] = useState([])

	const applyFilter = useCallback((data) => {
		return data
			.filter(row => {
				if (nameFilter) return row.title.toLowerCase().includes(nameFilter.toLowerCase())
				else return true
			})
			.filter(row => {
				if (pathFilter) return row.linkId.toLowerCase().includes(pathFilter.toLowerCase())
				else return true
			})
			.filter(row => {
				if (statusFilter !== null) return row.enabled === statusFilter
				else return true
			})
			.filter(row => {
				if (activityFilter !== null) return row.activityId === activityFilter
				else return true
			})
	}, [nameFilter, pathFilter, statusFilter, activityFilter])

	useEffect(() => {
		setFilteredRedirectsArr(applyFilter(redirectsArr))
	}, [redirectsArr, applyFilter, nameFilter, pathFilter, statusFilter, activityFilter])

	// Implement Modal ---

	const [editId, setEditId] = useState(null)
	const [showModal, setShowModal] = useState(false)

	const handleRowClick = useCallback((linkDef, e) => {
		setEditId(linkDef ? linkDef.linkId : null)
		setShowModal(true)
	}, [filteredRedirectsArr])

	const closeAction = useCallback(() => {
		setShowModal(false)
		setEditId(null)
		refreshTable()
	}, [refreshTable])

	// Render ---

	return <React.Fragment>
		<div className="section">
			<h1>URL Redirects</h1>
		</div>

		<div className="section">

			<div className='row' style={{ alignItems: 'end' }}>
				<div className="part">
					<TextInput
						label={'Name'}
						placeholder={''}
						value={nameFilter}
						onChange={value => setNameFilter(value)}
					/>
					<Dropdown
						label={'Activity'}
						items={['All', ...activitiesArr.map(activity => activity.DisplayName)]}
						selectedIndex={activityFilterIndex}
						onSelect={i => {
							setActivityFilterIndex(i)
							setActivityFilter(i === 0 ? null : activitiesArr[i - 1].ActivityEvent)
						}}
					/>
					<TextInput
						label={'Path'}
						placeholder={''}
						value={pathFilter}
						onChange={value => setPathFilter(value)}
					/>
					<Dropdown
						label={'Status'}
						items={['All', 'Enabled', 'Disabled']}
						selectedIndex={statusFilterIndex}
						onSelect={i => {
							setStatusFilterIndex(i)
							setStatusFilter(([null, true, false])[i])
						}}
					/>
				</div>
				<div className="part"><Button label='Add Redirect' onClick={() => handleRowClick()}/></div>
			</div>

		</div>

		<div className="section">
			<DataTable
				columns={columns}
				data={filteredRedirectsArr}
				onRowClicked={handleRowClick}

				theme="univ"
				pagination
			/>
		</div>

		{showModal && <Modal closeAction={closeAction}>
			<ReLink linkId={editId} closeAction={closeAction} activitiesArr={activitiesArr} activityCodeToName={activityCodeToName} />
		</Modal>}
	</React.Fragment>

}

export default Redirects